import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import intl from 'react-intl-universal';
import { DownOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import Cookie from '@utils/cookie';
import { Dropdown, Menu, Space } from 'antd';
import classnames from 'classnames';
import style from './index.module.less';
import MMenuChild from './menuChild';

const NavigateMap = [
    {
        id: '00',
        name: 'Menu.Home',
        link: '/',
        isOpen: false
    },
    {
        name: 'Menu.ServiceArea',
        link: '',
        id: '001',
        isOpen: false,
        children: [
            {
                name: 'Menu.CompanySecretary',
                link: '',
                id: '01',
                isOpen: false,
                children: [
                    {
                        name: 'Menu.OverseasRegistration',
                        id: '02',
                        link: '/services/offshore',
                        isOpen: false,
                        children: [
                            {
                                id: '03',
                                name: 'Menu.HKCompanyRegistration',
                                link: '/services/hongkong',
                                isOpen: false
                            },
                            {
                                id: '04',
                                name: 'Menu.SingaporeCompanyRegistration',
                                link: '/services/singapore',
                                isOpen: false
                            },
                            {
                                id: '05',
                                name: 'Menu.CaymanCompanyRegistration',
                                link: '/services/cayman',
                                isOpen: false
                            },
                            {
                                id: '06',
                                name: 'Menu.BVICompanyRegistration',
                                link: '/services/bvi',
                                isOpen: false
                            },
                            {
                                id: '07',
                                name: 'Menu.DubaiCompanyRegistration',
                                link: '/services/dubai',
                                isOpen: false
                            },
                            {
                                id: '08',
                                name: 'Menu.SeychellesCompanyRegistration',
                                link: '/services/seychelles',
                                isOpen: false
                            },
                            {
                                id: '09',
                                name: 'Menu.SaintVincentCompanyRegistration',
                                link: '/services/saintvincent',
                                isOpen: false
                            },
                            {
                                id: '10',
                                name: 'Menu.USCompanyRegistration',
                                link: '/services/usa',
                                isOpen: false
                            },
                            {
                                id: '11',
                                name: 'Menu.VanuatuCompanyRegistration',
                                link: '/services/vanuatu',
                                isOpen: false
                            },
                            {
                                id: '12',
                                name: 'Menu.MauritiusCompanyRegistration',
                                link: '/services/mauritius',
                                isOpen: false
                            },
                            {
                                id: '13',
                                name: 'Menu.KoreanCompanyRegistration',
                                link: '/services/korea',
                                isOpen: false
                            },
                            {
                                id: '14',
                                name: 'Menu.JapaneseCompanyRegistration',
                                link: '/services/japan',
                                isOpen: false
                            },
                            {
                                id: '15',
                                name: 'Menu.MalaysiaCompanyRegistration',
                                link: '/services/malaysia',
                                isOpen: false
                            }
                        ]
                    },
                    {
                        id: '16',
                        name: 'Menu.OverseasBankAccount',
                        link: '/services/bank',
                        isOpen: false
                    },
                    {
                        id: '17',
                        name: 'Menu.AuditService',
                        link: '/services/audit',
                        isOpen: false
                    },
                    {
                        id: '18',
                        name: 'Menu.InternationalCertificationNotarization',
                        link: '/services/notarization',
                        isOpen: false
                    },
                    {
                        id: '19',
                        name: 'Menu.ChinaCommissionedNotarization',
                        link: '/services/chinanotary',
                        isOpen: false
                    }
                ]
            },
            {
                name: 'Menu.ImmigrantVisa',
                link: '',
                id: '20',
                isOpen: false,
                children: [
                    {
                        name: 'Menu.ImmigrationServices',
                        link: '',
                        isOpen: false,
                        id: '21',
                        children: [
                            {
                                id: '22',
                                name: 'Menu.HKTalentsProgram',
                                link: '/services/hongkongym',
                                isOpen: false
                            },
                            {
                                id: '23',
                                name: 'Menu.HKMainlandTalentsProgram', // 暂时固定，待修改
                                link: '/services/hongkongzc',
                                isOpen: false
                            },
                            {
                                id: '24',
                                name: 'Menu.HKTopTalentsProgram', // 暂时固定，待修改
                                link: '/services/hongkonggc',
                                isOpen: false
                            },
                            // {
                            //     id: '21',
                            //     name: 'Menu.PortugalHousePurchaseImmigration',
                            //     link: '/services/portugalym',
                            //     isOpen: false
                            // },

                            {
                                id: '26',
                                name: 'Menu.HongKongCapitalInvestorEntryScheme',
                                link: '/services/cies',
                                isOpen: false
                            },
                            {
                                id: '27',
                                name: 'Menu.TurkeyInvestmentImmigration',
                                link: '/services/turkeyym',
                                isOpen: false
                            },
                            {
                                id: '28',
                                name: 'Menu.DubaiGoldenVisa',
                                link: '/services/dubaihjqz',
                                isOpen: false
                            }
                            // {
                            //     id: '23',
                            //     name: 'Menu.VanuatuPassport',
                            //     link: '/services/vanuatuym',
                            //     isOpen: false
                            // }
                        ]
                    },
                    {
                        name: 'Menu.VisaService',
                        link: '',
                        isOpen: false,
                        id: '30',
                        children: [
                            {
                                id: '31',
                                name: 'Menu.DubaiWorkTouristVisa',
                                link: '/services/dubaiqz',
                                isOpen: false
                            },
                            {
                                id: '32',
                                name: 'Menu.SingaporeWorkPermit',
                                link: '/services/singaporeqz',
                                isOpen: false
                            },
                            // {
                            //     id: '27',
                            //     name: 'Menu.ThailandEliteVisa',
                            //     link: '/services/thailandqz',
                            //     isOpen: false
                            // },
                            {
                                id: '34',
                                name: 'Menu.MauritiusSuperVisa',
                                link: '/services/mauritiusqz',
                                isOpen: false
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Menu.FinancialManagement',
                link: '',
                isOpen: false,
                id: '35',
                children: [
                    {
                        id: '36',
                        name: 'Menu.AccountingFinancialReporting',
                        link: '/services/accounting'
                    },
                    {
                        id: '37',
                        name: 'Menu.TaxAdvisoryCompliance',
                        link: '/services/tax'
                    }
                ]
            }
        ]
    },
    {
        id: '37-1',
        name: 'Menu.Malls',
        link: 'http://mall.finovy.cn/',
        isOpen: false
    },
    {
        id: '38',
        name: 'Menu.Offices',
        link: '/offices',
        isOpen: false
    },
    // {
    //     id: '33',
    //     name: 'Menu.RegistrationAdvantage',
    //     link: '/advantage',
    //     isOpen: false
    // },
    // {
    //     id: '34',
    //     name: 'Menu.Pricing',
    //     link: '/pricing',
    //     isOpen: false
    // },
    {
        id: '41',
        name: 'Menu.AboutUs',
        link: '/about',
        isOpen: false
    },
    {
        id: '42',
        name: 'Menu.ContactUs',
        link: '/contact',
        isOpen: false
    }
];
const langList = [
    {
        name: '简体中文',
        link: 'zh_CNS',
        locale: 'scc'
    },
    {
        name: '繁体中文',
        link: 'zh_CNT',
        locale: 'tc'
    },
    {
        name: 'English',
        link: 'en_US',
        locale: 'en'
    }
];
const formatLang = (lang: string) => {
    switch (lang) {
        case 'zh_CNS':
            return 'scc';
        case 'zh_CNT':
            return 'tc';
        case 'en_US':
            return 'en';
    }
};

const MMenu: React.FC = () => {
    const file_cdn = '/oss-service';
    const [open, setOpen] = useState(false);
    const [showChild, setShowChild] = useState(false);
    const [navigateMap, setNavigateMap] = useState(NavigateMap);
    const {
        options: { currentLocale }
    } = intl as any;
    const [langText, setLangText] = useState<string>('简体中文');
    const router = useRouter();
    useEffect(() => {
        const textList = langList.filter((item) => currentLocale === item.link);
        if (textList.length) {
            setLangText(textList[0].name);
        }
    }, [currentLocale]);
    useEffect(() => {
        const pathName = router.pathname;
        NavigateMap.forEach((item) => {
            if (pathName === item.link) {
                item.isOpen = true;
            } else {
                item.isOpen = false;
            }
            item.children &&
                item.children.forEach((item2) => {
                    if (pathName === item2.link) {
                        item.isOpen = true;
                        item2.isOpen = true;
                    } else {
                        item2.isOpen = false;
                    }
                    item2.children &&
                        item2.children.forEach((item3) => {
                            if (pathName === item3.link) {
                                item.isOpen = true;
                                item2.isOpen = true;
                                item3.isOpen = true;
                            } else {
                                item3.isOpen = false;
                            }
                            item3.children &&
                                item3.children.forEach((item4) => {
                                    if (pathName === item4.link) {
                                        item.isOpen = true;
                                        item2.isOpen = true;
                                        item3.isOpen = true;
                                        item4.isOpen = true;
                                    } else {
                                        item4.isOpen = false;
                                    }
                                });
                        });
                });
        });
        setNavigateMap(NavigateMap);
    }, [router, router.pathname]);

    // const navAct = (val: string) => {
    //     const path = router.pathname;
    //     return path === val;
    // };

    const handleClick = (link: string) => {
        if (link === router.pathname) {
            setOpen(false);
            return;
        }
        router.push(`/`, `/`);
    };
    const onChange = (value: any) => {
        Cookie.set('lang', value);
        router.push(`${router.pathname}`, `${router.asPath}`);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };
    const indexChange = (parentIndex: number, childIndex: number) => {
        console.log(parentIndex, childIndex, 'childIndex');
        const list = [...navigateMap];
        list.forEach((item, index) => {
            if (parentIndex === index) {
                item.children.forEach((item2, index2) => {
                    if (childIndex === index2) {
                        item2.isOpen = !item2.isOpen;
                    } else {
                        item2.isOpen = false;
                    }
                });
            }
        });
        setNavigateMap(list);
    };
    const childIndexChange = (parentIndex: number, childIndex: number, childIndex2: number) => {
        const list = [...navigateMap];
        list.forEach((item, index) => {
            if (parentIndex === index) {
                item.children.forEach((item2, index2) => {
                    if (childIndex === index2) {
                        item2.children.forEach((item3, index3) => {
                            if (childIndex2 === index3) {
                                item3.isOpen = !item3.isOpen;
                            } else {
                                item3.isOpen = false;
                            }
                        });
                    }
                });
            }
        });
        setNavigateMap(list);
    };
    const childIndexChange2 = (parentIndex: number, childIndex: number, childIndex2: number, childIndex3: number) => {
        const list = [...navigateMap];
        list.forEach((item, index) => {
            if (parentIndex === index) {
                item.children.forEach((item2, index2) => {
                    if (childIndex === index2) {
                        item2.children.forEach((item3, index3) => {
                            if (childIndex2 === index3) {
                                item3.children.forEach((item4, index4) => {
                                    if (childIndex3 === index4) {
                                        item4.isOpen = !item4.isOpen;
                                    } else {
                                        item4.isOpen = false;
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        setNavigateMap(list);
    };
    const listRender = () =>
        navigateMap.map((item: any, index: number) => {
            if (item.children) {
                return (
                    <div className={[style.languageWrap, showChild ? 'open' : ''].join(' ')} key={item.id}>
                        <div className={classnames(style.languageBtn, item.isOpen ? style.act : null)}>
                            <a>{intl.get(item.name) || item.name}</a>
                            <div
                                onClick={() => {
                                    const list = [...navigateMap];
                                    list.forEach((item, idx) => {
                                        if (index === idx) {
                                            item.isOpen = !item.isOpen;
                                        } else {
                                            item.isOpen = false;
                                        }
                                    });
                                    setNavigateMap(list);
                                }}
                            >
                                {item.isOpen ? (
                                    <img loading="lazy" className={style['icon-nav-arr']} src={`${file_cdn}/static/icon/red-arr.svg`} alt="" />
                                ) : (
                                    <img
                                        loading="lazy"
                                        className={classnames(style['icon-nav-arr'], style.default)}
                                        src={`${file_cdn}/static/icon/red-arr.svg`}
                                        alt=""
                                    />
                                )}
                            </div>
                        </div>
                        {item.isOpen && (
                            <div className={style.languageList}>
                                <MMenuChild
                                    subChildren={item.children}
                                    parentIndex={index}
                                    locale={formatLang(currentLocale)}
                                    indexChange={indexChange}
                                    childIndexChange={childIndexChange}
                                    child2IndexChange={childIndexChange2}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                );
            }
            if (index === 0) {
                return (
                    <div key={item.id}>
                        <span
                            onClick={() => {
                                handleClick(item.link);
                            }}
                        >
                            <a className={item.isOpen ? style['color-act'] : ''}>{intl.get(item.name) || item.name}</a>
                        </span>
                    </div>
                );
            }
            return (
                <div key={item.id}>
                    <span>
                        <Link href={item.link} legacyBehavior>
                            <a
                                // target={index === 2 ? '_blank' : '_self'}
                                onClick={() => {
                                    if (item.link === router.pathname) {
                                        setOpen(false);
                                        console.log(item.link);
                                    }
                                }}
                                className={item.isOpen ? style['color-act'] : ''}
                            >
                                {intl.get(item.name) || item.name}
                            </a>
                        </Link>
                    </span>
                </div>
            );
        });
    const menu = (
        <Menu>
            {langList.map((item) => (
                <Menu.Item
                    key={item.link}
                    onClick={() => {
                        onChange(item.link);
                    }}
                >
                    <div>{item.name}</div>
                </Menu.Item>
            ))}
        </Menu>
    );
    return (
        <>
            <div className={[style.mobileHeader, open ? style.open : ''].join(' ')}>
                <div className={style.headerWrap}>
                    <a
                        className={style.logoWrap}
                        onClick={() => {
                            router.push(`/`);
                        }}
                    >
                        <img loading="lazy" src={`${file_cdn}/static/menu/logo.svg`} alt="" />
                    </a>
                    <div className={style.navWrap}>
                        <Dropdown
                            className={style['dropdown-box']}
                            overlay={menu}
                            trigger={['click']}
                            overlayStyle={{ borderRadius: 4, overflow: 'hidden' }}
                        >
                            <Space>
                                {langText}
                                <DownOutlined className={style['dropdown-icon']} />
                            </Space>
                        </Dropdown>
                        <div className={style['mar-line']} />
                        <img loading="lazy" src={`${file_cdn}/static/menu/m-menu-icon.svg`} alt="" onClick={() => setOpen(true)} />
                    </div>
                </div>
            </div>
            <div className={classnames(style['nav-container'], open ? style.open : '')}>
                <div className={style.menuSecondList}>
                    <img
                        loading="lazy"
                        className={style.close}
                        src={`${file_cdn}/static/menu/icon-close.svg`}
                        alt=""
                        onClick={() => setOpen(false)}
                    />
                    {listRender()}
                </div>
                <div className={style.cover} />
            </div>
        </>
    );
};

export default MMenu;
