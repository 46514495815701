import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Col, Row } from 'antd';
import Cookie from '@utils/cookie';
import classnames from 'classnames';
// import { useSelector } from 'react-redux';
import MenuChild from './components/menuChild';
import Icon from '../IconFont';
import style from './style.module.less';

interface MenuProps {
    isMobile?: boolean;
}

const navigateMap = [
    {
        name: 'Menu.Home',
        link: '/'
    },
    {
        name: 'Menu.ServiceArea',
        link: '/services/offshore',
        children: [
            {
                name: 'Menu.OverseasRegistration',
                link: '/services/offshore'
            },
            {
                name: 'Menu.OverseasBankAccount',
                link: '/services/bank'
            },
            {
                name: 'Menu.AuditService',
                link: '/services/audit'
            },
            {
                name: 'Menu.Immigration',
                link: '/services/immigration'
            }
        ]
    },
    {
        name: 'Menu.Malls',
        link: 'http://mall.finovy.cn/'
    },
    {
        name: 'Menu.Offices',
        link: '/offices'
    },
    // {
    //     name: 'Menu.RegistrationAdvantage',
    //     link: '/advantage'
    // },
    // {
    //     name: 'Menu.Pricing',
    //     link: '/pricing'
    // },
    {
        name: 'Menu.AboutUs',
        link: '/about'
    },
    {
        name: 'Menu.ContactUs',
        link: '/contact'
    },
    {
        name: 'ENG/中',
        link: 'zh_CNS',
        children: [
            {
                name: '简体中文',
                link: 'zh_CNS'
            },
            {
                name: '繁体中文',
                link: 'zh_CNT'
            },
            {
                name: 'English',
                link: 'en_US'
            }
        ]
    }
];

const Menu: React.FC<MenuProps> = ({ isMobile }: MenuProps) => {
    const file_cdn = '/oss-service';
    const [showChild, setShowChild] = useState(false);
    const [showLangChild, setShowLangChild] = useState(false);
    const router = useRouter();
    const {
        options: { currentLocale }
    } = intl as any;

    const onChange = (value: any) => {
        if (currentLocale === value) return;
        Cookie.set('lang', value);
        router.push(`${router.pathname}`, `${router.asPath}`);
        setTimeout(
            () => {
                window.location.reload();
            },
            isMobile ? 500 : 100
        );
    };

    // const formatLang = (lang: string) => {
    //     switch (lang) {
    //         case 'zh_CNS':
    //             return 'sc';
    //         case 'zh_CNT':
    //             return 'tc';
    //         case 'en_US':
    //             return 'en';
    //     }
    // };

    // const changeRouter = (lang: string) => {
    //     switch (lang) {
    //         case 'zh_CNS':
    //             return 'sc';
    //         case 'zh_CNT':
    //             return 'tc';
    //         case 'en_US':
    //             return 'en';
    //     }
    // };
    const navAct = (val: string) => {
        const path = router.pathname;
        return path === val;
    };
    const navServiceAct = () => {
        const path = router.pathname;
        return path.indexOf('/services') !== -1;
    };

    const handleClick = () => {
        if (window && window.location.pathname === '/') return;
        router.push(`/`, `/`);
    };

    return (
        <div className={isMobile ? '' : style.containHeader}>
            <div className={style.commonHeader}>
                <Row>
                    <Col span={4}>
                        <div className={style.commonHeaderLogo} onClick={handleClick}>
                            <img loading="lazy" src={`${file_cdn}/static/menu/logo.svg`} alt="" />
                        </div>
                    </Col>
                    <Col span={20}>
                        <div className={style.commonHeaderNavigate}>
                            {navigateMap.map((item: any, index: number) =>
                                index === 1 ? (
                                    <div
                                        className={style.navigateTitle}
                                        key={item.link}
                                        onMouseEnter={() => setShowChild(true)}
                                        onMouseLeave={() => setShowChild(false)}
                                    >
                                        <span className={classnames(style.navigateName, navServiceAct() ? style['services-act'] : '')}>
                                            <span className={style['text-act']}>{intl.get(item.name)}</span>

                                            <Icon type="down" className={style.down} />
                                            {showChild && (
                                                <MenuChild
                                                    onClose={() => {
                                                        setShowChild(false);
                                                    }}
                                                />
                                            )}
                                        </span>
                                    </div>
                                ) : index === 0 ? (
                                    <div className={style.navigateTitle} key={item.link}>
                                        <span className={classnames(style.navigateName, navAct(item.link) ? style.act : '')} onClick={handleClick}>
                                            <span className={style['text-act']}>{intl.get(item.name)}</span>
                                        </span>
                                    </div>
                                ) : index === navigateMap.length - 1 ? (
                                    <div className={style.navigateTitle} key={item.link}>
                                        <div
                                            className={style.navigateName}
                                            onMouseEnter={() => setShowLangChild(true)}
                                            onMouseLeave={() => setShowLangChild(false)}
                                        >
                                            <img loading="lazy" alt="lang" src={`${file_cdn}/static/menu/lang.svg`} className={style.lang} />

                                            <span className={classnames(style['text-act'], style.eng)}>{item.name}</span>

                                            <Icon type="down" className={style.down} />

                                            {showLangChild && (
                                                <div lang={currentLocale} className={style.navigateList} onMouseLeave={() => setShowLangChild(false)}>
                                                    <div>
                                                        <div className={style.blank} />
                                                        {item.children.map((item: any) => (
                                                            <span
                                                                title={item.name}
                                                                key={item.link}
                                                                onClick={() => {
                                                                    onChange(item.link);
                                                                }}
                                                            >
                                                                <span className={style['text-act']}>{item.name}</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className={style.navigateTitle} key={item.link}>
                                        <span className={classnames(style.navigateName, navAct(item.link) ? style.act : '')}>
                                            <Link href={item.link} legacyBehavior>
                                                <a target={index === 2 ? '_blank' : '_self'} className={style['text-act']}>
                                                    {intl.get(item.name)}
                                                </a>
                                            </Link>
                                        </span>
                                    </div>
                                )
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            {/* )} */}
        </div>
    );
};

Menu.defaultProps = {
    isMobile: false
};

export default Menu;
